import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import Home from '../views/Home.vue'
import user from '../components/user.vue'
import role from '../components/role.vue'
import message from '../components/message.vue'
import welcome from '../components/welcome.vue'
import employee from '../components/employees.vue'
import category from '../components/category.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    redirect: '/home',
    meta:{title:"登录"}
  },
  {
    path: '/register',
    name: 'Register',
    component:Register,
    meta:{title:"注册"}
  },
  {
    path: '/home',
    name: 'Home',
    component:Home,
    redirect: '/welcome',
    meta:{title:"首页"},
    children:[
      {path:'/user',component:user,meta:{title:"用户列表"}},
      {path:'/product',component:role,meta:{title:"商品管理"}},
      {path:'/orders',component:employee,meta:{title:"订单管理"}},
      {path:'/welcome',component:welcome,meta:{title:"首页"}},
      {path:'/message',component:message,meta:{title:"资讯管理"}},
      {path:'/category',component:category,meta:{title:"分类管理"}},
    ]
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

router.beforeEach(async (to, from, next) => {
  if(to.meta.title){//判断是否有标题
    document.title = to.meta.title
  }
  if (to.path === '/login' || to.path === '/register'){
    return next();
  }
  const token = localStorage.getItem('token');
  if (!token) {
    return next('/login');
  } else {
    next();
  }
});
export default router
