<template>
  <el-container>
    <el-header>
      <div class="header-left">商城后台</div>
      <div class="header-right">
        <p>当前用户:</p>
        <p>{{ user}}</p>
        <el-button @click="exit" type="danger" plain>退出</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#fff"
          text-color="#666"
          :router="true"
          active-text-color="#409EFF"
        >
          <el-menu-item index="/welcome">
            <i class="el-icon-house"></i>
            <span slot="title">我的信息</span>
          </el-menu-item>
          <el-menu-item index="user">
            <i class="el-icon-user"></i>
            <span slot="title">用户信息</span>
          </el-menu-item>
          <el-menu-item index="category">
            <i class="el-icon-menu"></i>
            <span slot="title">分类管理</span>
          </el-menu-item>
          <el-menu-item index="message">
            <i class="el-icon-picture-outline"></i>
            <span slot="title">资讯管理</span>
          </el-menu-item>
          <el-menu-item index="product">
            <i class="el-icon-goods"></i>
            <span slot="title">商品管理</span>
          </el-menu-item>
          <el-menu-item index="orders">
            <i class="el-icon-tickets"></i>
            <span slot="title">订单信息</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      user:'',
      roles: [],
      pathUrl: "",
      userInfo: {},
      right:'',
      name:''
    };
  },
  async mounted(){
    this.user = localStorage.getItem("token");
  },
  methods: {
    exit() {
      localStorage.removeItem("user");
      this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss" scoped>
.el-container {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  .el-header {
    display: flex;
    background-color: #409eff;
    justify-content: space-between;
    align-items: center;
    .header-left {
      color: #fff;
      font-size: 26px;
      font-weight: bolder;
      font-family:'Segoe UI';
    }
    .header-right {
      display: flex;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 6px;
      }
      p {
        color: white;
        align-self: center;
        margin-right: 24px;
      }
    }
  }
  .el-aside {
    height: 100%;
    background-color: #fff;
  }
}
</style>
