<template>
  <div class="login-container">
    <div class="login-box">
      <h1>登录</h1>
      <el-form
        ref="form"
        label-position="right"
        label-width="0"
        :rules="rules"
        :model="loginData"
      >
        <el-form-item prop="userName">
          <el-input
            prefix-icon="el-icon-user"
            v-model="loginData.userName"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            type="password"
            v-model="loginData.password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 100%;background-color:#409eff;color:white"
            @click="formValidate('form')"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let validateUsername = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]{6,}$/;
      if (!value) {
        callback(new Error("请填写用户名"));
      } else if (value.length < 6) {
        callback(new Error("用户名至少是6位"));
      } else if (!reg.test(value)) {
        callback(new Error("用户名必须是数字字母组合"));
      } else {
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg = /^(?:(?=.*[0-9].*)(?=.*[A-Za-z].*)(?=.*[,\.#%'\+\*\-:;^_`].*))[,\.#%'\+\*\-:;^_`0-9A-Za-z]{8,}$/;
      if (!value) {
        callback(new Error("请填写密码"));
      } else if (value.length < 6) {
        callback(new Error("密码至少是6位"));
      } else {
        callback();
      }
    };
    return {
      loginData: {
        userName: "",
        password: "",
      },
      userInfo: "",
      rules: {
        userName: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    formValidate(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let res = await this.$request.fetch(
            "/users/adminLogin",
            this.loginData,
            "post"
          );
          if (res.status === 200) {
            localStorage.setItem('token',res.result.userName);
            this.$message.success('登录成功');
            this.$router.push({
              path:'/home',
              query: {
                user: this.loginData.userName
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    jump() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  background-color: #409eff;
  height: 100%;
  .login-box {
    width: 540px;
    height: 360px;
    background-color: #fff;
    border-radius: 3px;
    /*容器内居中*/
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    h1 {
      text-align: center;
    }
    .el-form {
      padding: 30px;
      box-sizing: border-box;
      span {
        display: block;
        width: 40px;
        height: 40px;
        position: relative;
        top: 0;
        left: 50px;
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    a {
      text-decoration: none;
      color: black;
    }
  }
}
</style>
