<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>仪表盘</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="never">
      <div className="box-card">
        <div style="padding: 20px 66px">
          <div className="text item"> {{ "用户名： " + tableData.userName ?? '---' }}</div>
          <div className="text item"> {{ "类型： " + '系统管理员' ?? '---'  }}</div>
          <div className="text item"> {{ "注册时间： " + tableData.createTime ?? '---' }}</div>
        </div>
      </div>
    </el-card>

    <el-card shadow="never">
      <div className="box-card">
        <div ref="chart" style="width: 100%;height:400px;"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import * as echarts from "echarts";

export default {
  data() {
    return {
      tableData: '',
      dayjs
    }
  },
  async mounted() {
    await this.getInfo();
  },
  methods: {
    async getInfo() {
      const userName = localStorage.getItem('token');
      let res = await this.$request.fetch('/users/userInfo', {userName}, 'post');
      if (res.status === 200) {
        this.tableData = res.data;
        this.tableData.createTime = dayjs(parseInt(this.tableData.createTime)).format('YYYY-MM-DD HH:mm:ss');
      }

      let result = await this.$request.fetch('/order/stats', {}, 'get');
      if (result.status === 200) {
        let rawData = result.result;
        let chartData = [];
        for (let i = 0; i < 14; i++) {
          let date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
          let data = rawData.find(item => dayjs(item.date).format('YYYY-MM-DD') === date);
          if (data) {
            chartData.unshift(data);
          } else {
            chartData.unshift({
              date: date,
              order_count: 0,
              total_amount: 0
            });
          }
        }
        this.chartData = chartData;
        this.initChart();
      }
    },
    initChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chart);

      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['成交额', '成交量']
        },
        grid: {
          left: '3%',  // 设置左边距
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartData.map(item => dayjs(item.date).format('YYYY-MM-DD'))
        },
        yAxis: [
          {
            type: 'value',
            name: '成交量',
            position: 'left',  // 设置y轴在左边
            // 刻度是1
            minInterval: 1
          },
          {
            type: 'value',
            name: '成交额',
            position: 'right',
          }
        ],
        series: [
          {
            name: '成交量',
            type: 'line',
            yAxisIndex: 0,  // 设置此系列使用第一个y轴
            data: this.chartData.map(item => item.order_count),
            color: '#fa9292'
          },
          {
            name: '成交额',
            type: 'bar',
            yAxisIndex: 1,  // 设置此系列使用第二个y轴
            data: this.chartData.map(item => item.total_amount),
            color: '#1a5ea5'
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    }

  }
};
</script>

<style scoped lang="scss">
.el-breadcrumb {
  padding-bottom: 20px;
  font-size: 1.2em;
  color: #333;
  box-sizing: border-box;
}
.box-card div {
  text-align: left;
}
.el-alert{
  margin: 20px 0;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
}
.el-card{
  min-height: 300px;
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  div{
    margin: 20px 0;
  }
}
.box-card{
  display: flex;
  align-content: center;
  padding: 20px;
  div{
    margin: 30px 50px;
  }
}
</style>
