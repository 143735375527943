import axios from 'axios';

export default class Request {
  static async fetch(url, jsonData = {}, method = 'get', responseType) {
    const baseUrl =  process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL + process.env.VUE_APP_API : process.env.VUE_APP_API;
    let config = {
      baseURL: baseUrl,
      method: method,
      timeout: 15000,
    };
    const token = localStorage.getItem('token');
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: token
    }
    config = Object.assign(config, responseType); // 设置返回的数据类型
    if (method === 'get') {
      config = { params: jsonData, ...config };
    } else {
      config = { data: jsonData, ...config }
    }
    return axios(url, config)
      .then(response => {
        if (response.data.status === 505) {
          localStorage.removeItem('token');
          this.$router.replace('/');
        }
        return response.data;
      })
      .catch(error => {
        return {
          status: 500,
          statusText: error.message || 'network error',
          data: { status: 500, msg: error.message || 'network error' },
        }
      });
  }
}
