import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'normalize.css/normalize.css'
import Request from '../src/plugins/request'
Vue.config.productionTip = false
Vue.prototype.$request = Request;
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

Vue.prototype.$target =  process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL + process.env.VUE_APP_API : process.env.VUE_APP_API;

Vue.use(Avue);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
