<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>资讯管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="never">
      <avue-crud ref="crud" v-model="user" :table-loading="loading" :data="tableData" @refresh-change="getList" :option="columns" :before-close="beforeClose" @row-save="rowSave" @row-update="rowUpdate" @row-del="rowDel">
        <template slot="detailForm" slot-scope="scope">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 400px; overflow-y: hidden;"
                v-model="user.detail"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </template>
        <template slot="imgPathForm" slot-scope="scope">
          <el-upload
              class="avatar-uploader"
              action="https://api.woftsun.cn/product/upload"
              accept="image/*"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-remove="handleRemove">
            <img v-if="user.imgPath" :src="$target + user.imgPath" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      dayjs,
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: 'https://api.woftsun.cn/product/upload',
            fieldName: 'file',
            allowedFileTypes: ['image/*'],
          },
        }
      },
      mode: 'default', // or 'simple'
      tableData: [],
      user:{},
      loading: false,
      columns: {
        align: 'center',
        menuAlign: 'center',
        menuWidth: 380,
        rowKey: 'product_id',
        // addBtn: false,
        border: true,
        stripe: true,
        index: true,
        // editBtn: false,
        // delBtn: false,
        column: [
          {
            label: 'id',
            prop: 'carousel_id',
            hide: true,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: '图片',
            prop: 'imgPath',
            slot: true
          },
          {
            label: '顺序',
            prop: 'describes',
            slot: true,
          },
            // 详情在表单里面占两格子
          {
            label: '详情',
            prop: 'detail',
            slot: true,

          }
        ]
      },
    }
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    beforeClose(done){
      done();
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    async getList() {
      this.loading = true;
      const result = await this.$request.fetch('/resources/carousel', {},'post');
      if (result.code === '001') {
        this.tableData = result.carousel;
        this.loading = false;
      }
    },
    async rowSave(form,done,loading) {
      const result = await this.$request.fetch('/resources/add', form, 'post');
      if (result.status === 200) {
        this.$message.success('新增成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('新增失败');
      }
    },
    async rowUpdate(form,index,done,loading) {
      const result = await this.$request.fetch('/resources/update', form, 'post');
      if (result.status === 200) {
        this.$message.success('更新成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('更新失败');
      }
    },
    async rowDel(form,index,done,loading) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await this.$request.fetch('/resources/delete', {carousel_id:form.carousel_id}, 'post');
        if (result.status === 200) {
          this.$message.success('删除成功');
          done(form);
          await this.getList();
        } else {
          loading(false);
          this.$message.success('删除失败');
        }
      })
    },
    handleSuccess(res, file, fileList) {
      this.user.imgPath = res.url;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 146px;
  line-height: 146px;
  text-align: center;
}
.avatar {
  display: block;
  width: 146px;
  height: 146px;
}
.el-breadcrumb {
  padding-bottom: 20px;
}
.el-card {
  min-height: 400px;
}
.add-user {
  margin-left: -20px;
}
.el-table {
  margin-bottom: 20px;
}
.search {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.search-btn {
  margin-left: 30px;
}
.search-item{
  width: 200px  !important;
}
</style>
