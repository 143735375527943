import { render, staticRenderFns } from "./welcome.vue?vue&type=template&id=6100a0da&scoped=true"
import script from "./welcome.vue?vue&type=script&lang=js"
export * from "./welcome.vue?vue&type=script&lang=js"
import style0 from "./welcome.vue?vue&type=style&index=0&id=6100a0da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6100a0da",
  null
  
)

export default component.exports