<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="never">
      <avue-crud ref="crud" v-model="user" :table-loading="loading" :data="tableData" @refresh-change="getList" :option="columns" :before-close="beforeClose" @row-save="rowSave" @row-update="rowUpdate" @row-del="rowDel">
        <template slot="createTime" slot-scope="scope">
          {{ dayjs(parseInt(scope.row.createTime)).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="type" slot-scope="scope">
          {{ scope.row.type === 2 ? '系统管理员' : '普通用户' }}
        </template>
        <template slot="passwordForm" slot-scope="scope">
          <el-input type="password" v-model="user.password" placeholder="请输入内容"></el-input>
        </template>
        <template slot="typeForm" slot-scope="scope">
          <el-select v-model="user.type" placeholder="请选择">
            <el-option label="系统管理员" :value="2"></el-option>
            <el-option label="普通用户" :value="1"></el-option>
          </el-select>
        </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      dayjs,
      tableData: [],
      user:{},
      loading: false,
      columns: {
        align: 'center',
        menuAlign: 'center',
        menuWidth: 380,
        rowKey: 'product_id',
        // addBtn: false,
        border: true,
        stripe: true,
        index: true,
        // editBtn: false,
        // delBtn: false,
        column: [
          {
            label: 'id',
            prop: 'category_id',
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: '分类名称',
            prop: 'category_name',
            slot: true
          },
          {
            label: '所属商品数量',
            prop: 'product_count',
            slot: true,
            editDisplay: false,
            addDisplay: false
          }
        ]
      },
    }
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    beforeClose(done){
      done();
    },
    async getList() {
      this.loading = true;
      const result = await this.$request.fetch('/product/getCategory', {},'post');
      if (result.code === '001') {
        this.tableData = result.category;
        this.loading = false;
      }
    },
    async rowSave(form,done,loading) {
      const result = await this.$request.fetch('/product/addCategory', form, 'post');
      if (result.status === 200) {
        this.$message.success('新增成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('新增失败');
      }
    },
    async rowUpdate(form,index,done,loading) {
      const result = await this.$request.fetch('/product/editCategory', form, 'post');
      if (result.status === 200) {
        this.$message.success('更新成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('更新失败');
      }
    },
    async rowDel(form,index,done,loading) {
      if (form.product_count > 0) {
        this.$message.error('该分类下有商品, 不能删除');
        return;
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await this.$request.fetch('/product/delCategory', {category_id:form.category_id}, 'post');
        if (result.status === 200) {
          this.$message.success('删除成功');
          done(form);
          await this.getList();
        } else {
          loading(false);
          this.$message.success('删除失败');
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  padding-bottom: 20px;
}
.el-card {
  min-height: 400px;
}
.add-user {
  margin-left: -20px;
}
.el-table {
  margin-bottom: 20px;
}
.search {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.search-btn {
  margin-left: 30px;
}
.search-item{
  width: 200px  !important;
}
</style>
