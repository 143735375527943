<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="never">
      <avue-crud v-model="user" :page="page" :data="tableData" :table-loading="loading" :option="columns" @row-save="rowSave" @size-change="sizeChange"
         @current-change="currentChange" @refresh-change="getList"  @row-update="rowUpdate" @row-del="rowDel">
        <template slot="createTime" slot-scope="scope">
          {{ dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="createTimeForm" slot-scope="scope">
          <el-date-picker
              v-model="user.createTime"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </template>
        <template slot="category_id" slot-scope="scope">
          {{ (categoryList.find(item => item.category_id === scope.row.category_id) || {}).category_name || '' }}
        </template>
        <template slot="product_picture" slot-scope="scope">
          <img :src="$target + scope.row.product_picture" style="width: 100px;height: 100px" alt>
        </template>
        <template slot="category_idForm" slot-scope="scope">
          <el-select v-model="user.category_id" placeholder="请选择">
            <el-option
              v-for="item in categoryList"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id">
            </el-option>
          </el-select>
        </template>
        <template slot="product_pictureForm" slot-scope="scope">
          <el-upload
              class="avatar-uploader"
              action="https://api.woftsun.cn/product/upload"
              :data="{ product_id: user.product_id }"
              accept="image/*"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-remove="handleRemove">
            <img v-if="user.product_picture" :src="$target + user.product_picture" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      dayjs,
      tableData: [],
      user:{},
      loading: false,
      dialogVisible: false,
      categoryList: [],
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      columns: {
        align: 'center',
        menuAlign: 'center',
        menuWidth: 180,
        dialogType: 'drawer',
        labelWidth: 130,
        border: true,
        stripe: true,
        index: true,
        rowKey: 'product_id',
        maxHeight: 700,
        column: [
          {
            label: 'id',
            prop: 'product_id',
            addDisplay: false,
            editDisplay: false,
            hide: true,
          },
          {
            label: '商品名称',
            prop: 'product_name',
            width: 200,
            fixed:true
          },
          {
            label: '分类',
            prop: 'category_id',
            width: 120,
            slot: true
          },
          {
            label: '商品标题',
            prop: 'product_title',
            slot: true,
            width: 240
          },
          {
            label: '商品描述',
            prop: 'product_intro',
            slot: true
          },
          {
            label: '商品主图',
            prop: 'product_picture',
            width: 240,
            slot: true
          },
          {
            label: '商品单价',
            prop: 'product_price',
            slot: true,
            width: 100
          },
          {
            label: '商品活动价',
            prop:'product_selling_price',
            width: 100
          },
          {
            label: '商品数量',
            prop: 'product_num',
            slot: true,
            width: 100
          },
          {
            label: '商品销量',
            prop: 'product_sales',
            slot: true,
            type: 'number',
            width: 100
          }
        ]
      },
    }
  },
  async mounted() {
    await this.getList();
    await this.getCategory();
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.user.product_picture = file.url;
      this.dialogVisible = true;
    },
    async getList() {
      this.loading = true;
      const result = await this.$request.fetch('/product/getAllProduct', {...this.page},'post');
      if (result.code === '001') {
        this.tableData = result.Product;
        this.page.total = result.total;
        this.loading = false;
      }
    },
    async rowSave(form,done,loading) {
      const result = await this.$request.fetch('/product/add', form, 'post');
      if (result.status === 200) {
        this.$message.success('新增成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('新增失败');
      }
    },
    async rowUpdate(form,index,done,loading) {
      const result = await this.$request.fetch('/product/update', form, 'post');
      if (result.status === 200) {
        this.$message.success('更新成功');
        done(form);
        await this.getList();
      } else {
        loading(false);
        this.$message.success('更新失败');
      }
    },
    async rowDel(form,index,done,loading) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await this.$request.fetch('/product/delete', {product_id:form.product_id}, 'post');
        if (result.status === 200) {
          this.$message.success('删除成功');
          done(form);
          await this.getList();
        } else {
          this.$message.success('删除失败');
        }
      })
    },
    async getCategory() {
      const result = await this.$request.fetch('/product/getCategory', {},'post');
      if (result.code === '001') {
        this.categoryList = result.category;
      }
    },
    handleSuccess(res, file, fileList) {
      this.user.product_picture = res.url;
    },
    currentChange(val) {
      this.page.currentPage = val;
      this.getList();
    },
    sizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 146px;
  line-height: 146px;
  text-align: center;
}
.avatar {
  display: block;
  width: 146px;
  height: 146px;
}
.el-breadcrumb {
  padding-bottom: 20px;
}
.el-card {
  min-height: 400px;
}
.add-user {
  margin-left: -20px;
}
.el-table {
  margin-bottom: 20px;
}
.search {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.search-btn {
  margin-left: 30px;
}
.search-item{
  width: 200px  !important;
}
</style>
