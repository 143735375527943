<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="never">
      <avue-crud v-model="user" :data="tableData" :option="columns" @row-del="rowDel">
        <template slot="order_time" slot-scope="scope">
          {{ dayjs(scope.row.order_time).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="danger" size="mini" @click="rowDel(scope.row)">删除订单</el-button>
        </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      dayjs,
      tableData: [],
      user:{},
      columns: {
        align: 'center',
        menuAlign: 'center',
        menuWidth: 380,
        addBtn: false,
        border: true,
        stripe: true,
        index: true,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: 'id',
            prop: '_id',
            addDisplay: false,
            editDisplay: false,
            hide: true,
          },
          {
            label: '订单编号',
            prop: 'order_id',
          },
          {
            label: '商品名称',
            prop: 'product_name',
          },
          {
            label: '商品数量',
            prop: 'product_num',
          },
          {
            label: '用户名',
            prop: 'userName',
            slot: true
          },
          {
            label: '下单时间',
            prop: 'order_time',
            addDisplay: false,
            editDisplay: false,
            slot: true
          },
          {
            label: '金额',
            prop: 'product_price',
            slot: true
          },
        ]
      },
    }
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      const result = await this.$request.fetch('/order/getAllOrder', {}, 'post');
      if (result.status === 200) {
        this.tableData = result.result;
      }
    },
    async rowDel(form,index,done,loading) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await this.$request.fetch('/order/deleteOrder', {order_id: form.order_id}, 'post');
        if (result.status === 200) {
          this.$message.success('删除成功');
          await this.getList();
        } else {
          loading(false);
          this.$message.success('删除失败');
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  padding-bottom: 20px;
}
.el-card {
  min-height: 400px;
}
.add-user {
  margin-left: -20px;
}
.el-table {
  margin-bottom: 20px;
}
.search {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.search-btn {
  margin-left: 30px;
}
.search-item{
  width: 200px  !important;
}
</style>
